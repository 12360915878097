<template>
  <div>
    <v-card class="pa-8 pb-5">
      <v-card-text>
        <slot name="cardHeader" v-if="showHeaderSlot" />

        <BaseHeadline fake="2" color="highEmphasis" class="mb-7" v-if="!showHeaderSlot">
          {{ $t(title) }}
        </BaseHeadline>

        <slot v-if="!loading" />

        <v-progress-circular indeterminate color="primary" v-if="loading" />
      </v-card-text>
    </v-card>
    <div class="text-right mt-5">
      <slot name="cardFooter" />
    </div>
  </div>
</template>

<script>
export default {
  name: "OutsideCard",

  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    showHeaderSlot() {
      return !!this.$slots.cardHeader;
    },
  },
};
</script>
