<template>
  <v-row no-gutters class="mb-2">
    <v-col cols="auto" align-self="center" class="mb-1 mr-2">
      <v-icon color="success" v-if="value" small>far fa-check-circle</v-icon>
      <v-icon color="error" v-else small>far fa-times-circle</v-icon>
    </v-col>
    <v-col cols="auto" align-self="center">
      <BaseText>{{ $t(text) }}</BaseText>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PolicyChecker",
  props: {
    value: Boolean,
    text: String,
  },
};
</script>
