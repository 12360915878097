<template>
  <BaseFrontdoor keepOut>
    <OutsideContainer>
      <CreateNewPasswordForm />
    </OutsideContainer>
  </BaseFrontdoor>
</template>

<script>
import OutsideContainer from "@components/Layout/OutsideContainer.vue";
import CreateNewPasswordForm from "./partials/CreateNewPasswordForm.vue";

export default {
  name: "CreateNewPassword",

  components: {
    OutsideContainer,
    CreateNewPasswordForm,
  },
};
</script>
