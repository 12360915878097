<template>
  <v-row>
    <v-col cols="12" class="mb-n3">
      <BaseTextField
        type="password"
        label="PASSWORD.CREATE_LABEL_PASSWORD"
        v-model="passwordValue"
      />
    </v-col>

    <v-col cols="12" class="mb-n3">
      <BaseTextField
        type="password"
        label="PASSWORD.CREATE_LABEL_PASSWORD_CONFIRM"
        v-model="passwordConfirmValue"
      />
    </v-col>

    <v-col cols="12" v-if="mfa" class="mb-n3">
      <BaseTextField label="PASSWORD.CREATE_LABEL_MFA_TOKEN" v-model="mfaTokenValue" />
    </v-col>

    <v-col cols="12">
      <PolicyChecker :error="error" :value="passwordLength" text="PASSWORD.POLICY_LENGTH" />
      <PolicyChecker :error="error" :value="passwordLetters" text="PASSWORD.POLICY_LETTERS" />
      <PolicyChecker :error="error" :value="passwordNumbers" text="PASSWORD.POLICY_NUMBERS" />
      <PolicyChecker :error="error" :value="passwordSymbols" text="PASSWORD.POLICY_SYMBOLS" />
      <PolicyChecker :error="error" :value="passwordsEqual" text="PASSWORD.POLICY_EQUAL" />
    </v-col>
  </v-row>
</template>

<script>
import regex from "@data/regex";
import PolicyChecker from "@components/FormElements/Password/partials/PolicyChecker.vue";

export default {
  name: "PasswordCreate",

  components: {
    PolicyChecker,
  },

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        password: null,
        passwordConfirm: null,
        mfaToken: null,
      }),
    },

    error: {
      type: Boolean,
      required: false,
      default: false,
    },

    mfa: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    passwordLength() {
      if (!this.passwordValue) return false;

      return regex.passwordLength.test(this.passwordValue);
    },

    passwordLetters() {
      if (!this.passwordValue) return false;

      return regex.passwordLetters.test(this.passwordValue);
    },

    passwordNumbers() {
      if (!this.passwordValue) return false;

      return regex.passwordNumbers.test(this.passwordValue);
    },

    passwordSymbols() {
      if (!this.passwordValue) return false;

      return regex.passwordSymbols.test(this.passwordValue);
    },

    passwordsEqual() {
      if (!this.passwordValue || !this.passwordConfirmValue) return false;

      return this.passwordValue === this.passwordConfirmValue;
    },

    passwordPolicy() {
      if (!this.passwordValue) return false;

      return regex.passwordPolicy.test(this.passwordValue);
    },

    passwordComplete() {
      return this.passwordPolicy && this.passwordsEqual;
    },

    passwordValue: {
      get() {
        return this.value.password;
      },
      set(value) {
        this.$emit("input", { ...this.value, password: value });
      },
    },

    passwordConfirmValue: {
      get() {
        return this.value.passwordConfirm;
      },
      set(value) {
        this.$emit("input", { ...this.value, passwordConfirm: value });
      },
    },

    mfaTokenValue: {
      get() {
        return this.value.mfaToken;
      },
      set(value) {
        this.$emit("input", { ...this.value, mfaToken: value });
      },
    },
  },

  watch: {
    passwordComplete() {
      this.$emit("complete", this.passwordComplete);
    },
  },
};
</script>
