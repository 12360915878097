<template>
  <OutsideCard title="PASSWORD.CREATE_NEW_FORM_TITLE">
    <v-row>
      <v-col cols="12">
        <CreatePassword v-model="password" @complete="setPassword($event)" />
      </v-col>

      <v-col cols="12">
        <div class="text-right mt-3">
          <BaseButton
            @click="activate"
            :loading="isLoading"
            :disabled="!passwordComplete || isLoading"
          >
            {{ $t("PASSWORD.CREATE_NEW_FORM_BUTTON_LABEL") }}
          </BaseButton>
        </div>
      </v-col>
    </v-row>
  </OutsideCard>
</template>

<script>
import CreatePassword from "@components/FormElements/Password/Create";
import { mapActions } from "vuex";
import OutsideCard from "@components/Layout/OutsideCard.vue";
import store from "@store";

export default {
  name: "CreateNewPasswordCompleteForm",

  components: {
    OutsideCard,
    CreatePassword,
  },

  data() {
    return {
      isLoading: false,
      password: {
        password: null,
        passwordConfirm: null,
      },
      passwordComplete: null,
      userId: null,
    };
  },

  computed: {
    token() {
      return this.$route.query.token ?? null;
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapActions("authUser", ["showAuthUser"]),

    async goTo(route) {
      try {
        await this.$router.push(route);
      } catch {
        return;
      }
    },

    setPassword(event) {
      this.passwordComplete = event;
    },

    async retrieveUserId() {
      const { data } = await this.$axios.auth.createNewPasswordShow(this.token);
      this.userId = data.data.id;
    },

    async activate() {
      if (!this.passwordComplete) {
        return;
      }

      this.isLoading = true;

      try {
        const { data } = await this.$axios.auth.createNewPassword({
          userId: this.userId,
          token: this.token,
          password: this.password.password,
          passwordConfirmation: this.password.passwordConfirm,
        });

        await this.loginOrRedirect(data);
      } catch (error) {
        console.error(error);

        if (error.response.status === 409) {
          await this.notify({
            type: "error",
            message: "PASSWORD.CREATE_NEW_FORM_SAME_AS_OLD_PASSWORD",
          });
        }
      } finally {
        this.isLoading = false;
      }
    },

    async loginOrRedirect({ token }) {
      if (token) {
        await store.dispatch("auth/saveAccessToken", {
          accessToken: token.access_token,
          refreshToken: token.refresh_token,
        });

        await this.showAuthUser();

        await this.goTo({ name: "SeminarSearch" });
      } else {
        await this.notify({
          type: "success",
          message: "PASSWORD.CREATE_NEW_SUCCESS",
        });

        await this.goTo({ name: "Login" });
      }
    },
  },

  created() {
    if (!this.token) {
      this.goTo({ name: "Login" });
    }

    this.retrieveUserId();
  },
};
</script>
